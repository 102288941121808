import './Style.css'
import * as React from 'react';
import { Navbar } from 'react-bootstrap';
import bible from '../../images/bible.png';

export default function Header() {

  return (
    <>
      <Navbar bg="light" sticky="top">
        <Navbar.Brand href="#home">
          <img
            src={bible}
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="Misamobile"
          />{' '}
          Misamobile
        </Navbar.Brand>
      </Navbar>
    </>
  )
}