import * as React from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import './Style.css';

interface Props {
  number: string;
  reading: string;
  source: string;
}

const Reading: React.FC<Props> = (props) => {

  const getTitle = () => {
    switch (props.number) {
      case '1':
        return 'First reading';
      case '2':
        return 'Second reading';
      case '3':
        return 'Gospel';
      default:
        return '';
    }
  }

  return (
    <Card>
      <Card.Header>
        <Accordion.Toggle as={Button} variant="link" eventKey={props.number}>
          {getTitle()}
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={props.number}>
      <Card.Body>
        <p className="title">{props.source}</p>
        <p>{props.reading}</p>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default Reading;
