import readings from './readings';
import alert from './alert';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { StateInterface } from '../Interfaces/StateInterface';
import storage from 'redux-persist/lib/storage' ;

const reducers = combineReducers<StateInterface>({
  readings, alert
});

const rootPersistConfig = {
  key: 'misamobile',
  storage,
  blacklist: ['alert']
}

export default persistReducer(rootPersistConfig, reducers);