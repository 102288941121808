import 'bootstrap/dist/css/bootstrap.min.css';
import * as React from 'react';
import './App.css';
import Header from './views/Header';


const App: React.FC = (props) => {

  
  return (
    <div className="App">
      <Header />
      {props.children}
    </div>
  );
}

export default App;
