import { ALERT } from '../actions/actionTypes';
import { AlertInterface } from '../Interfaces/AlertInterface';
import { AnyAction } from 'redux';

const INITIAL_STATE: AlertInterface = {
  visible: false,
  message: '',
  title: ''
};

const alert = (state: AlertInterface = INITIAL_STATE, action: AnyAction): AlertInterface => {
  switch(action.type) {
    case ALERT.SET_ALERT:
      if (action.alert) {
        return action.alert;
      }
      return state;
    default:
      return state;
  }
};

export default alert;