import * as React from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';

interface Props {
  response: string;
  verses: string[];
  source: string;
}

const Responsorial: React.FC<Props> = (props) => {

  return (
    <Card>
      <Card.Header className={'text-center'}>
        <Accordion.Toggle as={Button} variant="link" eventKey="0">
          <u>Responsorial psalm</u>
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey="0">
        <Card.Body>
          <p>
            <strong>{props.source}</strong>
          </p>
          {
            props.verses.map((verse, index) => {
              return (
                <React.Fragment key={`responsorial-${index}`}>
                  <p>
                    <strong>
                      <u>Response : {props.response}</u>
                    </strong>
                  </p>
                  <p>{verse}</p>
                </React.Fragment >
              )
            })
          }
          <p>
            <strong>
              <u>Response : {props.response}</u>
            </strong>
          </p>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default Responsorial;
