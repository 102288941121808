import { READINGS, ReadingActionTypes } from '../actions/actionTypes';
import { Readings } from '../Interfaces/ReadingInterface';

const readings = (state: Readings = {}, action: ReadingActionTypes) => {
  switch(action.type) {
    case READINGS.READINGS_LOADED:
      const newState = {...state};
      newState[action.payload.date] = action.payload;
      return newState;
    default:
      return state;
  }
};

export default readings;