import * as React from 'react';
import Readings from '../Readings';
import { Form } from 'react-bootstrap';

export const Home = () => {
  
  const getDate = (d: Date) => {
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  }
  const today = new Date();
  const [date, setDate] = React.useState(getDate(today));


  const onDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDate(getDate(new Date(e.target.value)));
  }

  const pad = (value: string | number) => {
    if (Number(value) < 10) {
      return `0${value}`;
    }
    return value;
  }

  const formatDate = (input: string) => {
    const parts = input.split('/');
    return `${parts[2]}-${pad(parts[1])}-${pad(parts[0])}`;
    // return new Date();
  }

  return (
    <>
      <Readings
        date={date}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="mx-auto">
            <Form.Group
              controlId="select-date"
            >
              <Form.Label>Select date</Form.Label>
              <Form.Control
                id="select-date"
                type="date"
                as="input"
                placeholder="Select date"
                value={formatDate(date)}
                onChange={onDateChange}
              />
            </Form.Group>
          </div>
        </div>
      </div>
    </>
  )
}