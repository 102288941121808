import thunk, { ThunkMiddleware } from 'redux-thunk';
import { StateInterface } from '../Interfaces/StateInterface';
import { applyMiddleware, createStore } from 'redux'
import rootReducers from '../reducers';
import { storeMiddleWare } from '../middleware/';
import { persistStore } from 'redux-persist';

let middlewares = [thunk as ThunkMiddleware<StateInterface, any>, storeMiddleWare];

export const configureStore = () => {
  const store = createStore(rootReducers, {}, applyMiddleware(...middlewares));
  return { store, persistedStore: persistStore(store) };
}