import * as React from 'react';
import { Switch } from 'react-router';
import { HashRouter } from 'react-router-dom';
import { Route } from 'react-router';
import App from '../App';
import { Privacy } from '../views/privacy';
import { Home } from '../views/home';

export const Routes = () => {

  return (
    <HashRouter>
      <Switch>
        <Route
          exact={true}
          path={["/", "/home"]}
          render={({ location }) => {
            return (
            <App>
              <Home />
            </App>
            );
          }}
        />
        <Route
          exact={true}
          path={'/privacy'}
          render={({ location }) => {
            return (
              <App>
                <Privacy />
              </App>
            );
          }}
        />
      </Switch>
      </HashRouter>
  )
};
