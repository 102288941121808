import * as React from 'react';
import './Style.css';
import { Accordion, Alert, ProgressBar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { DayReading } from '../../Interfaces/ReadingInterface';
import { StateInterface } from '../../Interfaces/StateInterface';
import Reading from '../components/Reading';
import Responsorial from '../components/Resposorial';
import { Action } from 'redux';
import { getReadings } from '../../actions/readingActions';
import { AlertInterface } from '../../Interfaces/AlertInterface';
import { updateAlert } from '../../actions/alertAction';

interface Props {
  date: string;
}

interface StateProps {
  reading?: DayReading;
  alert: AlertInterface;
}

interface ActionProps {
  getReadings: (date: string) => void;
  updateAlert: (alert: AlertInterface) => void;
}

type ReadingProps = StateProps & ActionProps & Props;

const Readings = (props: ReadingProps) => {
  const [loading, setLoading] = React.useState(false);
  const [date, setDate] = React.useState<undefined | string>(undefined);
  const [show, setShow] = React.useState(props.alert.visible);

  React.useEffect(() => {
    if ((props.reading === undefined) && !loading && props.date !== date) {
      setLoading(true);
      props.getReadings(props.date);
    } else if (props.reading && loading) {
      setLoading(false);
    } else if (loading && props.alert.visible) {
      setLoading(false);
      setShow(true);
      setTimeout(() => {
        setShow(false);
        props.updateAlert({...props.alert, visible: false });
      }, 3000)
    }
    if (props.date !== date) {
      setDate(props.date);
    }
  }, [props, loading, date]);
  let items = null;
  if (loading) {
    items = (
      <ProgressBar
        now={100}
        animated={true}
        striped={true}
      />
    );
  } else if (props.reading) {
    items = props.reading.readings.map((reading, index) => {
      return (
        <Reading
          number={reading.readingNumber}
          source={reading.source}
          reading={reading.reading.replace(/\\/g, '')}
          key={`reading-${index}`}
        />
      )
    });
    items.splice(1, 0, (
      <Responsorial
        key={`responsorial`}
        {...props.reading.responsorial}
      />
      )
    );
    document.title = `Misamobile | ${props.reading.period}`;
    items = (
      <>
        <p className="period">{props.reading.period}</p>
        <Accordion defaultActiveKey="1">
          {items}
        </Accordion>
      </>
    )
  }

  const closeAlert = () => {
    const { alert } = props;
    props.updateAlert({...alert, visible: false });
    setLoading(false);
    setShow(false);
  }

  const alert = props.alert.visible ? (
    <Alert
      variant="warning"
      dismissible={true}
      onClose={() => closeAlert()}
    >
      <label>{props.alert.message}</label>
    </Alert>
  ) : null;
  return (
    <div className="container-fluid body">
      {alert}
      {items}
    </div>
  )
}

const mapStateToProps = (state: StateInterface, props: Props): StateProps => {
  return {
    reading: state.readings[props.date],
    alert: state.alert
  }
}

const mapActionToProps = (dispatch: ThunkDispatch<StateInterface, any, Action>): ActionProps => {
  return {
    getReadings: (date: string) => dispatch(getReadings(date)),
    updateAlert: (alert: AlertInterface) => dispatch(updateAlert(alert))
  }
}

export default connect(mapStateToProps, mapActionToProps)(Readings);
