import { DayReading } from "../Interfaces/ReadingInterface";

export const READINGS = {
  FETCH_READINGS: 'FETCH_READINGS',
  READINGS_LOADED: 'READINGS_LOADED'
};

interface ReadingsLoadedAction {
  type: typeof READINGS.READINGS_LOADED;
  payload: DayReading;
}

export type ReadingActionTypes = ReadingsLoadedAction;

export const ALERT = {
  SET_ALERT: 'SET_ALERT'
};