import { READINGS, ReadingActionTypes } from './actionTypes';
import { DayReading } from '../Interfaces/ReadingInterface';
import { Dispatch } from 'redux';
import { CONSTANTS } from '../utils/Constants';
import { StateInterface } from '../Interfaces/StateInterface';
import { updateAlert } from './alertAction';

export const getReadings = (date?: string) => {
  return (dispatch: Dispatch, getState: () => StateInterface) => {
    const request = fetch(`${CONSTANTS.APP_URL}/get_reading_new.php${date ? `?date=${date}` : ''}`);
    const alert = {
      visible: false,
      title: 'Error',
      message: 'Readings not available, we will update soon.'
    }
    request.then(response => response.json()).then(json => {
      if (json.period !== '' && json.readings.length > 0) {
        dispatch(readingsLoaded(json));
      } else {
        alert.visible = true;
        dispatch(updateAlert(alert));
      }
    }).catch((error) => {
      alert.visible = true;
      alert.message = 'Error fetching readings.'
      dispatch(updateAlert(alert));
      console.log(error);
    });
  };
};

export const readingsLoaded = (readings: DayReading): ReadingActionTypes => (
  { type: READINGS.READINGS_LOADED, payload: readings}
)